import React, { useEffect, useState } from 'react';
import { useLogto } from '@logto/react';

const ApiCall = () => {
  const { getAccessToken } = useLogto();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        //const token = await getAccessToken('https://demop.locomations.com'); // Get the Logto token
        const token = await getAccessToken(); // Get the Logto token
        const response = await fetch('https://demop.locomations.com/get-user-info', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user info');
        }

        const data = await response.json();
        setUserInfo(data); // Assuming the API returns user data as JSON
      } catch (err: unknown) {
        if (err instanceof Error) {
          setError(err.message);  // Now TypeScript knows 'err' is of type 'Error'
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, [getAccessToken]); // This hook runs when the component is mounted

  if (loading) return <p>Loading user info...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h3>User Info:</h3>
      {userInfo ? (
        <pre>{JSON.stringify(userInfo, null, 2)}</pre> // Display the user info
      ) : (
        <p>No user info available.</p>
      )}
    </div>
  );
};

export default ApiCall;
