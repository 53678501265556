import { useLogto } from '@logto/react'

const SignOut = () => {
	const { signOut } = useLogto();

	return (
		<button onClick={() => signOut('https://demor.locomations.com')}>
		Sign Out
		</button>
	);
};

export default SignOut;
