import { useLogto } from '@logto/react'

const SignIn = () => {
	const { signIn, isAuthenticated } = useLogto();
	if (isAuthenticated) {
		return <div>Signed In</div>
	}

	return (
		<button onClick={() => signIn('https://demor.locomations.com/callback')}>
		SignIn
		</button>
	);
};

export default SignIn;
