import React from 'react';
import { LogtoProvider, LogtoConfig, UserScope } from '@logto/react';

import './App.css';
import Home from './Home';
import Callback from './Callback';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
	const config: LogtoConfig = {
		endpoint: 'https://signin.locomations.com/',
		appId: '3v23azftkikb6rwyz5z2c',
		scopes: [
			UserScope.Email,
			UserScope.Phone,
			UserScope.CustomData,
		],
		resources: ['https://demop.locomations.com'],
	};

	return (
		<BrowserRouter>
			<LogtoProvider config={config}>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/callback" element={<Callback />} />
				</Routes>
			</LogtoProvider>
		</BrowserRouter>
	);
}

export default App;
