import { useLogto } from '@logto/react';
import SignIn from './SignIn';
import SignOut from './SignOut';
import logo from './logo.svg';
import ApiCall from './ApiCall';

const Home = () => {
	const { isAuthenticated } = useLogto();
	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<SignIn />
				{isAuthenticated && (
          				<>
			        	<SignOut />
            				<ApiCall /> {/* Render ApiCall when authenticated */}
          				</>
        			)}
			</header>
		</div>
	)
}

export default Home;
